import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic01 from '../assets/images/overground/5.jpeg'
import pic02 from '../assets/images/overground/6.jpeg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const Overground = (props) => (
    <Layout>
        <Helmet>
            <title>Overground Tanks</title>
            <meta name="Overground Tanks" content="Landing Page" />
        </Helmet>

        {/* <BannerLanding /> */}

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Overground Tanks</h2>
                    </header>
                </div>
            </section>

            <Slider {...settings}>
                <div className="image">
                    <img src={pic02} alt="" />
                </div>
                <div className="image">
                    <img src={pic01} alt="" />
                </div>
            </Slider>

            <section id="two" className="spotlights">
                <section>
                    <div className="content">
                        <div className="inner">
                            <p>All above ground fuel tanks supplied by Marcon Tanks are manufactured from steel to a design that meets the requirements for environmental guidance and legislation. Above ground fuel tanks are supplied in a double skinned or totally enclosed configuration that provides secondary containment in the event of a leak or a spillage.</p>

                            <p>Above ground storage tanks are available to suit a wide range of applications and can be supplied with storage capacities from as little as 100 litres up to those in excess of 200,000 litres. These tanks can be supplied as a single vessel or can be compartmentised to contain other products such as diesel, gas oil, lubrication oil, hydraulic oil, waste oil and many other oil products.</p>

                            <p>All pipework connections to the above ground storage tank system comply fully with environmental guidance and legislation and are designed in such a way as prevent tampering, leakage or spillage.</p>
                        </div>
                    </div>
                </section>
                    
            </section>
        </div>

    </Layout>
)

export default Overground